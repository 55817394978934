<template>
    <div class="team-members">
        <div class="row">
            <div class="col-12">
                <h6 class="oml-h6Title">
                    {{ $t('VAPI') }} 
                    <a href="https://vapi.ai" target="_blank" class="ml-1 color-blue">
                    <small>{{ $t('getVapiApiKey') }}</small>
                    </a>
                </h6>
            </div>
        </div>

        <div class="profile_forms col-md-12 mt-3 pl-0">
            <label for="vapiApiKey" class="form-label">{{ $t('vapiApiKey') }}</label>
            <div class="form-group d-flex mb-3">
                <input 
                    id="vapiApiKey"
                    type="text" 
                    class="form-control h-40" 
                    v-model="vapiApiKey" 
                    :placeholder="$t('vapiApiKey')"
                    required
                />
                <button type="button" class="oml-btn ml-2" @click="validateVapiKey()" :disabled="validatingKey">
                    <i class="fa fa-spinner fa-spin" v-if="validatingKey"></i> 
                    {{ $t('Validate') }}
                </button>
            </div>
            <div v-if="vapiKeyValidated || vapiConnected">
                <!-- Ajout du toggle switch pour activer/désactiver -->
                <div class="form-group d-flex align-items-center mb-3">
                    <label class="toggle-switch mr-2">
                        <input type="checkbox" v-model="vapiActive" @change="toggleVapiActive">
                        <span class="slider round"></span>
                    </label>
                    <span class="ml-2">{{ vapiActive ? $t('StatusActive') : $t('StatusInactive') }}</span>
                </div>
                
                <label for="vapiAssistantId" class="form-label">{{ $t('vapiAssistantId') }}</label>
                <div class="form-group d-flex mb-3">
                    <input 
                        id="vapiAssistantId"
                        type="text" 
                        class="form-control h-40" 
                        v-model="vapiAssistantId" 
                        :placeholder="$t('vapiAssistantId')"
                    />
                </div>
                <label for="vapiNumberId" class="form-label">{{ $t('vapiNumberId') }}</label>
                <div class="form-group d-flex mb-3">
                    <input 
                        id="vapiNumberId"
                        type="text" 
                        class="form-control h-40" 
                        v-model="vapiNumberId" 
                        :placeholder="$t('vapiNumberId')"
                    />
                </div>
                <div class="form-group d-flex">
                    <button type="submit" class="oml-btn" @click="updateVapiSettings()" :disabled="vapiLoading">
                        <i class="fa fa-spinner fa-spin" v-if="vapiLoading"></i> 
                        {{ $t('Update') }}
                    </button>
                    <button type="button" class="oml-btn ml-2 btn-danger" @click="deleteVapiSettings()" :disabled="deleteLoading">
                        <i class="fa fa-spinner fa-spin" v-if="deleteLoading"></i> 
                        {{ $t('Delete') }}
                    </button>
                </div>
            </div>

            <small v-if="vapiError" class="color-red">* {{vapiErrorMessage}}</small>

            <div v-if="validationSuccess" class="alert alert-success mt-3">
                {{ $t('keyValidatedAndSaved') }}
            </div>
            <div v-if="deleteSuccess" class="alert alert-info mt-3">
                {{ $t('settingsDeleted') }}
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h6 class="oml-h6Title">
                    Ritel
                    <span class="ml-2 badge badge-secondary">{{ $t('ComingSoon') }}</span>
                </h6>
            </div>
        </div>

        <div class="profile_forms col-md-12 mt-3 pl-0 disabled-section">
            <label for="ritelApiKey" class="form-label">{{ $t('ritelApiKey') }}</label>
            <div class="form-group d-flex mb-3">
                <input 
                    id="ritelApiKey"
                    type="text" 
                    class="form-control h-40" 
                    :placeholder="$t('ritelApiKey')"
                    disabled
                />
                <button type="button" class="oml-btn ml-2" disabled>
                    {{ $t('Validate') }}
                </button>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h6 class="oml-h6Title">
                    SynthFlow
                    <span class="ml-2 badge badge-secondary">{{ $t('ComingSoon') }}</span>
                </h6>
            </div>
        </div>

        <div class="profile_forms col-md-12 mt-3 pl-0 disabled-section">
            <label for="synthflowApiKey" class="form-label">{{ $t('synthflowApiKey') }}</label>
            <div class="form-group d-flex mb-3">
                <input 
                    id="synthflowApiKey"
                    type="text" 
                    class="form-control h-40" 
                    :placeholder="$t('synthflowApiKey')"
                    disabled
                />
                <button type="button" class="oml-btn ml-2" disabled>
                    {{ $t('Validate') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ai-voice',
    data() {
        return {
            vapiApiKey: "",
            vapiAssistantId: "",
            vapiNumberId: "",
            vapiActive: false,
            vapiLoading: false,
            deleteLoading: false,
            toggleLoading: false,
            vapiConnected: false,
            vapiError: false,
            vapiErrorMessage: "",
            validatingKey: false,
            validationSuccess: false,
            deleteSuccess: false,
            vapiKeyValidated: false
        }
    },
    methods: {
        validateVapiKey() {
            if (!this.vapiApiKey) {
                this.vapiError = true
                this.vapiErrorMessage = this.$t('ApiKeyRequired')
                return
            }

            this.validatingKey = true
            this.vapiError = false
            this.validationSuccess = false
            this.deleteSuccess = false

            const url = 'vapi/validate-key'
            const method = 'POST'
            const data = { api_key: this.vapiApiKey }

            this.$store.dispatch('apiRequest', { url, method, data })
                .then((response) => {
                    if (response.data.success) {
                        this.validationSuccess = true
                        this.vapiKeyValidated = true
                        this.vapiConnected = true
                        if (response.data.settings) {
                            this.vapiAssistantId = response.data.settings.assistant_id || '';
                            this.vapiNumberId = response.data.settings.number_id || '';
                            this.vapiActive = response.data.settings.active || false;
                        }
                        this.notifyMe(this.$t('keyValidatedAndSaved'), 'success')
                    } else {
                        this.vapiError = true
                        this.vapiErrorMessage = response.data.message || this.$t('invalidApiKey')
                    }
                })
                .catch(({response}) => {
                    this.vapiError = true
                    this.vapiErrorMessage = response?.data?.message || this.$t('validationError')
                })
                .finally(() => {
                    this.validatingKey = false
                })
        },

        updateVapiSettings() {
            this.vapiLoading = true
            this.vapiError = false
            this.deleteSuccess = false

            const url = 'vapi/update'
            const method = 'POST'
            const data = {
                assistant_id: this.vapiAssistantId,
                number_id: this.vapiNumberId,
                active: this.vapiActive
            }

            this.$store.dispatch('apiRequest', { url, method, data })
                .then((response) => {
                    if (response.data.success) {
                        this.notifyMe(response.data.message, 'success')
                    } else {
                        this.vapiError = true
                        this.vapiErrorMessage = response.data.message || this.$t('UpdateError')
                    }
                })
                .catch(({response}) => {
                    this.vapiError = true
                    this.vapiErrorMessage = response?.data?.message || this.$t('UpdateError')
                })
                .finally(() => {
                    this.vapiLoading = false
                })
        },
        
        toggleVapiActive() {
            this.toggleLoading = true;
            
            const url = 'vapi/toggle-active';
            const method = 'POST';
            const data = {};
            
            this.$store.dispatch('apiRequest', { url, method, data })
                .then((response) => {
                    if (response.data.success) {
                        this.vapiActive = response.data.active;
                        this.notifyMe(response.data.message, 'success');
                    } else {
                        this.vapiError = true;
                        this.vapiErrorMessage = response.data.message || this.$t('ToggleError');
                        // Réinitialiser la valeur en cas d'échec
                        this.vapiActive = !this.vapiActive;
                    }
                })
                .catch(({response}) => {
                    this.vapiError = true;
                    this.vapiErrorMessage = response?.data?.message || this.$t('ToggleError');
                    // Réinitialiser la valeur en cas d'échec
                    this.vapiActive = !this.vapiActive;
                })
                .finally(() => {
                    this.toggleLoading = false;
                });
        },
        
        deleteVapiSettings() {
            if (confirm(this.$t('confirmDeleteSettings'))) {
                this.deleteLoading = true;
                this.vapiError = false;
                this.validationSuccess = false;
                this.deleteSuccess = false;

                const url = 'vapi/delete';
                const method = 'DELETE';
                const data = {};

                this.$store.dispatch('apiRequest', { url, method, data })
                    .then((response) => {
                        if (response.data.success) {
                            // Réinitialiser tous les champs
                            this.vapiApiKey = "";
                            this.vapiAssistantId = "";
                            this.vapiNumberId = "";
                            this.vapiActive = false;
                            this.vapiConnected = false;
                            this.vapiKeyValidated = false;
                            
                            this.deleteSuccess = true;
                            this.notifyMe(this.$t('settingsDeleted'), 'success');
                        } else {
                            this.vapiError = true;
                            this.vapiErrorMessage = response.data.message || this.$t('DeleteError');
                        }
                    })
                    .catch(({response}) => {
                        this.vapiError = true;
                        this.vapiErrorMessage = response?.data?.message || this.$t('DeleteError');
                    })
                    .finally(() => {
                        this.deleteLoading = false;
                    });
            }
        },
        
        getVapiSettings() {
            const url = 'vapi/settings'
            const method = 'GET'
            let data = {}
            this.settingsLoading = true
            this.$store.dispatch('apiRequest', { url, method, data })
                .then((response) => {
                    if (response.data.success) {
                        this.vapiApiKey = response.data.api_key
                        this.vapiAssistantId = response.data.assistant_id
                        this.vapiNumberId = response.data.number_id
                        this.vapiActive = response.data.active || false

                        if (response.data.api_key) {
                            this.vapiConnected = true 
                            this.vapiKeyValidated = true
                        }
                    }
                })
                .catch((error) => {
                    console.log('Erreur lors de la récupération des paramètres Vapi:', error)
                    this.vapiConnected = false
                })
                .finally(() => {
                    this.settingsLoading = false
                })
        },
        
        notifyMe(message, variant) {
            this.$notify({
                group: 'foo',
                text: message,
                type: variant,
            });
        }
    },
    mounted() {
        this.getVapiSettings()
    }
}
</script>

<style lang="scss">
    /* Styles pour le toggle switch */
    .toggle-switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
        margin-bottom: 0;
    }

    .toggle-switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        transform: translateX(26px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    
    /* Styles spécifiques aux labels */
    .form-label {
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-size: 0.9rem;
    }
    
    /* Styles pour le bouton de suppression */
    .btn-danger {
        background-color: #dc3545;
        border-color: #dc3545;
        color: white;
        
        &:hover {
            background-color: #bd2130;
            border-color: #bd2130;
        }
    }
    
    /* Style pour les sections désactivées */
    .disabled-section {
        opacity: 0.6;
        cursor: not-allowed;
    }
    
    .badge-secondary {
        background-color: #6c757d;
        color: white;
        font-weight: normal;
        font-size: 12px;
        padding: 5px 8px;
    }
    
    /* Réutilisez les mêmes styles que dans Integrations.vue */
    .table-grid {
        thead th {
            font-weight: normal !important;
            font-size: 14px;
        }
    }
    
    .custom-control-inline {
        width: 8em;
    }
    
    [role-tooltip] {
        position:relative;
    }
    [role-tooltip]:before {
        position: absolute;
        left: -52px;
        top: -52px;
        font-size: 12px;
        height: 4em;
        background-color: #171717;
        color: #ffffff;
        border-radius: 5px;
        padding: 5px 8px;
        content: attr(content);
        display: none;
        transition: .25s ease-in-out;
        font-weight: 600;
        width: 30em;
    }
    [role-tooltip]:after {
        position: absolute;
        right: 30%;
        top: -4px;
        border-top: 7px solid #171717;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        content: "";
        display: none;
        transition:.25s ease-in-out;
    }
    [role-tooltip]:hover:after, [role-tooltip]:hover:before {
        display: block;
        transition:.25s ease-in-out;
    }
    
    .fb-table {
        thead {
            th {
                & > div {
                    color: #8c8c8c;
                    font-weight: normal;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    .fa {
                        opacity: 0.66;
                    }
                }
            }
        }
    }
</style>